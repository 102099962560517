.footer {
    width: 100%;
    height: 80dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #0303AB;
    padding: 20px 80px;
    box-sizing: border-box;
}

.footer .footerWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.footer .footerWrapper .footerCard {
    width: 33.33%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer .footerWrapper .footerCard .footerImg {
    width: fit-content;
    height: fit-content;
}

.footer .footerWrapper .footerCard .footerImg > img {
    width: 80px;
    height: 80px;
}

.footer .footerWrapper .footerCard .footerTxt {
    width: 90%;
    height: fit-content;
    border-radius: 4px;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    margin-top: 16px;
}

.footer .footerWrapper .footerCard .footerHeader {
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 1.3px;
    color: #fff;
    text-transform: capitalize;
    margin: 0;
    margin-bottom: 16px;
}

.footer .footerWrapper .footerCard > ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer .footerWrapper .footerCard > ul li {
    list-style: none;
    margin: 4px 0;
}

.footer .footerWrapper .footerCard > ul li a {
    text-decoration: none;
    color: #fff;
    letter-spacing: 1.3px;
    transition: all .6s ease-in-out;
}

.footer .footerWrapper .footerCard > ul li a:hover {
    letter-spacing: 1px;
    transition: all .6s ease-in-out;
}

.footer .footerWrapper .footerCard .subscribeWrapper {
    width: 100%;
    height: fit-content;
}

.footer .footerWrapper .footerCard .subscribeWrapper .subscribeInput {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer .footerWrapper .footerCard .subscribeWrapper .subscribeInput > input {
    width: calc(100% - 32px);
    padding: 15px 10px;
    border: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    outline: none;
    box-sizing: border-box;
}

.footer .footerWrapper .footerCard .subscribeWrapper .subscribeInput > button {
    border: none;
    padding: 15px 32px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #FF6620;
    font-weight: bold;
    color: #fff;
}

.footer .footerWrapper .footerCard .subscribeWrapper > p {
    padding: 0;
    margin: 0;
    font-style: italic;
}

.footer .footerWrapper .footerCard .socialWrapper {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer .footerWrapper .footerCard .socialWrapper > h4 {
    color: #fff;
    font-size: 1.5rem;
    margin: 0;
    margin-top: 16px;
    margin-bottom: 16px;
}

.footer .footerWrapper .footerCard .socialWrapper .socials {
    display: flex;
}

.footer .footerWrapper .footerCard .socialWrapper .socials a .social {
    color: #fff;
    margin: 4px;
    font-size: 1.3rem;
    transition: all .6s ease-in-out;
}

.footer .footerWrapper .footerCard .socialWrapper .socials a .social:hover {
    color: #FF6620;
    transition: all .6s ease-in-out;
    transform: scale(1.1, 1.1);
    cursor: pointer;
}

/* Desktop Styling for 1200px screens and up */
@media screen and (min-width: 1200px) {
    .footer {
        padding: 20px 80px;
    }

    .footer .footerWrapper {
        flex-direction: row;
    }

    .footer .footerWrapper .footerCard {
        width: 33.33%;
    }
}

/* Styling for 1024px screens */
@media screen and (max-width: 1024px) {
    .footer {
        padding: 20px 40px;
    }

    .footer .footerWrapper {
        flex-direction: row;
    }

    .footer .footerWrapper .footerCard {
        width: 45%;
        margin-bottom: 16px;
    }

    .footer .footerWrapper .footerCard .footerTxt {
        padding: 20px;
    }
}

/* Tablet Styling for 768px Screen */
@media screen and (max-width: 768px) {
    .footer {
        padding: 20px 30px;
    }

    .footer .footerWrapper {
        flex-direction: column;
        align-items: center;
    }

    .footer .footerWrapper .footerCard {
        width: 90%;
        margin-bottom: 16px;
    }

    .footer .footerWrapper .footerCard .footerTxt {
        padding: 20px;
    }
}

/* Tablet Styling for 600px screen */
@media screen and (max-width: 600px) {
    .footer {
        padding: 20px 20px;
    }

    .footer .footerWrapper {
        flex-direction: column;
        align-items: center;
    }

    .footer .footerWrapper .footerCard {
        width: 90%;
        margin-bottom: 16px;
    }

    .footer .footerWrapper .footerCard .footerTxt {
        padding: 20px;
    }
}

/* Mobile Styling on 480px */
@media screen and (max-width: 480px) {
    .footer {
        height: 100%;
        padding: 20px;
    }

    .footer .footerWrapper {
        flex-direction: column;
        align-items: center;
    }

    .footer .footerWrapper .footerCard {
        width: 90%;
        height: fit-content;
        margin-bottom: 16px;
    }

    .footer .footerWrapper .footerCard .footerTxt {
        padding: 20px;
        margin-top: 8px;
    }
}

/* Mobile Styling on 320px */
@media screen and (max-width: 320px) {
    .footer {
        height: 100%;
        padding: 20px;
    }

    .footer .footerWrapper {
        flex-direction: column;
        align-items: center;
    }

    .footer .footerWrapper .footerCard {
        width: 90%;
        height: fit-content;
        margin-bottom: 16px;
    }

    .footer .footerWrapper .footerCard .footerTxt {
        padding: 20px;
        margin-top: 8px;
    }
}
