.contactUsContent {
    width: 100%;
    height: 100dvh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    box-sizing: border-box;
    margin-top: 5%;
    margin-bottom: 10%;
}

.contactUsContent .contactUsContentWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerSection {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(rgba(0,0,0,0.1), rgba(3, 3, 171, 1)), url(./../../assets/images/TechtenPlanet-contactus.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 20px 80px;
    box-sizing: border-box;
}

.contactUsContent .contactUsContentWrapper .socials {
    margin-top: 15%;
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 2%;
}

.contactUsContent .contactUsContentWrapper .socials .social {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 2px solid #0303AB;
    border-radius: 8px;
    display: flex;
    align-items: center;
    margin: 8px 0;
}

.contactUsContent .contactUsContentWrapper .socials .social .socialIcon {
    font-size: 2rem;
    color: #0303AB;
    margin-right: 8px;
}

.contactUsContent .contactUsContentWrapper .contactForm {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 40px;
    box-sizing: border-box;
    margin-top: 30%;
}

.header {
    font-size: 2.5rem;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 1.3px;
    margin: 0;
    margin-bottom: 1px;
    text-align: center;
}

.text {
    font-size: 1rem;
    font-weight: 100;
    color: #ffffff;
    letter-spacing: 1px;
    margin: 0;
    margin-bottom: 1px;
    text-align: center;
}

.contactUsContent .contactUsContentWrapper .contactForm > form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: center;
}

.contactUsContent .contactUsContentWrapper .contactForm > form .top {
    width: 100%;
    display: flex;
    height: fit-content;
    justify-content: space-between;
}

.contactUsContent .contactUsContentWrapper .contactForm > form .top .inputs label,
.contactUsContent .contactUsContentWrapper .contactForm > form .inputs label {
    margin-bottom: 8px;
}

.contactUsContent .contactUsContentWrapper .contactForm > form .top .inputs,
.contactUsContent .contactUsContentWrapper .contactForm > form .inputs {
    width: 49%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin: 8px 0;
}

.contactUsContent .contactUsContentWrapper .contactForm > form .inputs {
    width: 100%;
}

.contactUsContent .contactUsContentWrapper .contactForm > form .top .inputs > input,
.contactUsContent .contactUsContentWrapper .contactForm > form .inputs > input,
.contactUsContent .contactUsContentWrapper .contactForm > form .inputs > textarea {
    padding: 10px;
    box-sizing: border-box;
    border: 2px solid #0303AB;
    outline: none;
    width: 100%;
    border-radius: 4px;
}

.contactUsContent .contactUsContentWrapper .contactForm > form .inputs > input,
input,
.contactUsContent .contactUsContentWrapper .contactForm > form .inputs > textarea {
    width: 100%;
}

.contactUsContent .contactUsContentWrapper .contactForm > form .sendMessage {
    width: 100%;
    padding: 16px 0;
    box-sizing: border-box;
    border: none;
    background-color:#0303AB;
    color: #fff;
    letter-spacing: 1.3px;
    border-radius: 4px;
    margin: 0;
    margin-top: 16px;
}

/* Desktop Styling for 1200px screens and up */
@media screen and (min-width: 1200px) {
    .contactUsContent {
        height: 100dvh;
        padding: 20px 0;
    }

    .contactUsContent .contactUsContentWrapper .socials {
        margin-top: 10%;
        width: 30%;
    }

    .contactUsContent .contactUsContentWrapper .contactForm {
        width: 70%;
        margin-top: 30%;
    }
}

/* Styling for 1024px screens */
@media screen and (max-width: 1024px) {
    .contactUsContent {
        height: 90dvh;
        padding: 20px 0;
    }

    .contactUsContent .contactUsContentWrapper .socials {
        margin-top: 12%;
        width: 35%;
    }

    .contactUsContent .contactUsContentWrapper .contactForm {
        width: 65%;
        margin-top: 25%;
    }
}

/* Tablet Styling for 768px Screen */
@media screen and (max-width: 768px) {
    .contactUsContent {
        height: 80dvh;
        padding: 20px 0;
    }

    .contactUsContent .contactUsContentWrapper .socials {
        margin-top: 15%;
        width: 40%;
    }

    .contactUsContent .contactUsContentWrapper .contactForm {
        width: 60%;
        margin-top: 30%;
    }
}

/* Tablet Styling for 600px screen */
@media screen and (max-width: 600px) {
    .contactUsContent {
        height: 70dvh;
        padding: 20px 0;
    }

    .contactUsContent .contactUsContentWrapper .socials {
        margin-top: 20%;
        width: 45%;
    }

    .contactUsContent .contactUsContentWrapper .contactForm {
        width: 55%;
        margin-top: 35%;
    }
}

/* Mobile Styling on 480px */
@media screen and (max-width: 480px) {
    .contactUsContent {
        width: 100%;
        height: fit-content;
        padding: 2px 0;
        margin-top: 5%;
        margin-bottom: 10%;
    }

    .contactUsContent .contactUsContentWrapper {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .headerSection {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: linear-gradient(rgba(0,0,0,0.1), rgba(3, 3, 171, 1)), url(./../../assets/images/TechtenPlanet-contactus.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 20px 10px;
    }

    .contactUsContent .contactUsContentWrapper .socials {
        margin-top: 300px;
        width: 90%;
        height: 100%;
    }

    .header {
        font-size: 1.5rem;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 1.3px;
        margin: 0;
        margin-bottom: 1px;
        text-align: center;
    }

    .text {
        font-size: 1rem;
        font-weight: 100;
        color: #ffffff;
        letter-spacing: 1px;
        margin: 10px;
        margin-bottom: 1px;
        text-align: center;
    }

    .contactUsContent .contactUsContentWrapper .contactForm {
        width: 100%;
        margin-top: 0;
        padding: 10px;
    }

    .contactUsContent .contactUsContentWrapper .contactForm > form .top .inputs,
    .contactUsContent .contactUsContentWrapper .contactForm > form .inputs {
        width: 100%;
    }
}

/* Mobile Styling on 320px */
@media screen and (max-width: 320px) {
    .contactUsContent {
        width: 100%;
        height: fit-content;
        padding: 2px 0;
        margin-top: 5%;
        margin-bottom: 10%;
    }

    .contactUsContent .contactUsContentWrapper {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .headerSection {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: linear-gradient(rgba(0,0,0,0.1), rgba(3, 3, 171, 1)), url(./../../assets/images/TechtenPlanet-contactus.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 20px 10px;
    }

    .contactUsContent .contactUsContentWrapper .socials {
        margin-top: 300px;
        width: 90%;
        height: 100%;
    }

    .header {
        font-size: 1.2rem;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 1.1px;
        margin: 0;
        margin-bottom: 1px;
        text-align: center;
    }

    .text {
        font-size: 0.9rem;
        font-weight: 100;
        color: #ffffff;
        letter-spacing: 1px;
        margin: 10px;
        margin-bottom: 1px;
        text-align: center;
    }

    .contactUsContent .contactUsContentWrapper .contactForm {
        width: 100%;
        margin-top: 0;
        padding: 10px;
    }

    .contactUsContent .contactUsContentWrapper .contactForm > form .top .inputs,
    .contactUsContent .contactUsContentWrapper .contactForm > form .inputs {
        width: 100%;
    }
}
