.Benefit {
    background-color: #f5f5f5;
    padding: 0;
    margin-top: 50px;
    border-bottom: 1px solid #ccc; /* Add bottom border */
}

.miniTile {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    margin-top: 1px;
    margin-bottom: 20px;
}

.miniWrapper {
    max-width: 100%;
    max-height: fit-content;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 5px;
    width: auto;
    margin-left: 0;
    margin-right: 0;
}

.header {
    font-size: 30px;
    font-weight: bold;
    text-align: left;
    color: #000000;
    letter-spacing: 1.3px;
    text-transform: capitalize;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 100px;
}

.card {
    width: 33%;
    max-width: fit-content;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: safe;
    position: relative;
    padding: 5px;
    margin: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 30px 20px;
    border: 2px solid #0303AB;
    color: black;
    border-radius: 4px;
    box-sizing: border-box;
    transition: all .6s ease-in-out;
}

.card:hover {
    box-shadow: 0px 0px 16px #a7a7a754;
    transition: all .6s ease-in-out;
}

.cardHeading {
    font-size: 24px;
    font-weight: bold;
    color: #FF6620;
    letter-spacing: 1.3px;
    text-transform: capitalize;
    margin-top: 10px;
    margin-bottom: 20px;
}

.cardBrief {
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 50px;
    position: relative; /* Set the content to relative positioning */
    padding-bottom: 150px; /* Add bottom padding to create space between content and image */
    min-height: 150px; /* Set a minimum height for the content */
}

.cardImg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; /* Ensures the image spans the full width of the card */
    height: 175px;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    object-fit: cover;
    border-radius: 4px;
    overflow: clip;
    margin-bottom: 20px;
}

/* Desktop Styling for 1200px screens and up */
@media screen and (min-width: 1200px) {
    .Benefit {
        padding: 0;
        margin-top: 50px;
    }

    .miniTile {
        height: 100vh;
        margin-top: 1px;
    }

    .header {
        font-size: 30px;
        margin-left: 100px;
    }

    .card {
        width: 30%;

        padding: 30px 20px;
    }

    .cardHeading {
        font-size: 24px;
    }

    .cardBrief {
        font-size: 18px;
        padding-bottom: 150px;
        min-height: 150px;
    }
}

/* Styling for 1024px screens */
@media screen and (max-width: 1024px) {
    .miniTile {
        height: 100%;
        margin-top: 32px;
    }

    .miniWrapper {
        justify-content: center;
    }

    .card {
        width: 45%;
        margin: 10px;
    }

    .header {
        font-size: 28px;
        margin-left: 50px;
    }
}

/* Tablet Styling for 768px Screen */
@media screen and (max-width: 768px) {
    .miniTile {
        height: 100%;
        margin-top: 32px;
    }

    .miniWrapper {
        justify-content: center;
    }

    .card {
        width: 45%;
        margin: 10px;
    }

    .header {
        font-size: 26px;
        margin-left: 20px;
    }

    .cardHeading {
        font-size: 22px;
    }

    .cardBrief {
        font-size: 16px;
    }
}

/* Tablet Styling for 600px screen */
@media screen and (max-width: 600px) {
    .miniTile {
        height: auto;
        margin-top: 32px;
    }

    .miniWrapper {
        flex-direction: column;
        align-items: center;
    }

    .card {
        width: 70%;
        margin-bottom: 32px;
    }

    .header {
        font-size: 24px;
        margin-left: 10px;
    }

    .cardHeading {
        font-size: 20px;
    }

    .cardBrief {
        font-size: 14px;
    }
}

/* Mobile Styling on 480px */
@media screen and (max-width: 480px) {
    .Benefit {
        padding: 10px 0;
        margin-top: 50px;
        border-bottom: 1px solid #ccc;
    }

    .miniWrapper {
        flex-direction: column;
        align-items: center;
        margin: 5px 0;
    }

    .header {
        font-size: 22px;
        margin-left: 10px;
    }

    .card {
        width: 90%;
        margin: 10px;
        padding: 30px 20px;
    }

    .cardHeading {
        font-size: 20px;
    }

    .cardBrief {
        font-size: 14px;
        padding-bottom: 150px;
        min-height: 150px;
    }
}

/* Mobile Styling on 320px */
@media screen and (max-width: 320px) {
    .Benefit {
        padding: 10px 0;
        margin-top: 50px;
        border-bottom: 1px solid #ccc;
    }

    .miniWrapper {
        flex-direction: column;
        align-items: center;
        margin: 5px 0;
    }

    .header {
        font-size: 20px;
        margin-left: 10px;
    }

    .card {
        width: 90%;
        margin: 10px;
        padding: 30px 20px;
    }

    .cardHeading {
        font-size: 18px;
    }

    .cardBrief {
        font-size: 12px;
        padding-bottom: 150px;
        min-height: 150px;
    }
}
