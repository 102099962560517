.aboutSection {
    width: 100%;
    height: 100dvh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 6px;
    padding: 10px 40px;
    box-sizing: border-box;
    flex-direction: column;
}

.aboutSection .intro {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: #0303AB;
    width: fit-content;
    margin: 0;
    margin-bottom: 8px;
}

.aboutSection .intro > span {
    padding: 3px;
    border: 3px solid #0303AB;
    background-color: #0303AB;
    border-radius: 50px;
    margin: 0px 8px;
}

.aboutSection .aboutSectionWrapper {
    width: 80%;
    height: 450px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 32px;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0px 0px 16px #a7a7a71e;
    border-radius: 4px;
    transition: all .6s ease-in-out;
    display: inline-flex;
    margin-bottom: 1%;
}

.aboutSection .aboutSectionWrapper .aboutSectionImg, .aboutSection .aboutSectionWrapper .aboutSectionTxt {
    max-width: 100%;
    width: fit-content;
    height: 95%;
    padding: 20px;
    padding-left: 10px;
    box-sizing: border-box;
}

.aboutSection .aboutSectionWrapper .aboutSectionImg > img {
    max-width: 100%;
    height: 350px;
    border-radius: 4px;
    object-fit: cover;
    box-shadow: 0px 0px 16px #a7a7a71e;
    padding: 0;
    margin: 0;
}

.aboutSection .aboutSectionWrapper .aboutSectionTxt > h3 {
    font-size: 2.0em;
    font-weight: bold;
    text-align: left;
    letter-spacing: 1.3px;
    line-height: 1.2;
    color: #0303AB;
    margin-bottom: 12px;
}

.aboutSectionTxt {
    font-size: 18px;
    font-weight: 300;
    color: #000000;
    text-align: left;
    margin-bottom: 4px;
    text-wrap: wrap;
}

.blockquote {
    padding: 6px 8px 4px;
    position: relative;
}

.blockquote p {
    font-family: "Utopia-italic";
    font-size: 35px;
    font-weight: 700;
    text-align: center;
}

.blockquote:before {
    position: absolute;
    font-family: 'FontAwesome';
    top: 0;
    content: "\f10d";
    font-size: 200px;
    color: rgba(0,0,0,0.1);
}

.blockquote::after {
    content: "";
    top: 20px;
    left: 50%;
    margin-left: -100px;
    position: absolute;
    border-bottom: 3px solid #FF6620;
    height: 3px;
    width: 200px;
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic);
.outblockquote {
    font-size: 1.4em;
    width: 60%;
    margin: 50px auto;
    font-family: Open Sans;
    font-style: italic;
    color: #555555;
    padding: 1.2em 30px 1.2em 75px;
    border-left: 8px solid #0303AB;
    line-height: 1.6;
    position: relative;
    background: #EDEDED;
}

.outblockquote::before {
    font-family: Arial;
    content: "\201C";
    color: #0303AB;
    font-size: 4em;
    position: absolute;
    left: 10px;
    top: -10px;
}

.outblockquote::after {
    content: '';
}

.outblockquote span {
    display: block;
    color: #FF6620;
    font-style: normal;
    font-weight: bold;
    margin-top: 1em;
}

/* Desktop Styling for 1200px screens and up */
@media screen and (min-width: 1200px) {
    .aboutSection {
        height: 100dvh;
        padding: 10px 40px;
    }

    .aboutSection .aboutSectionWrapper {
        width: 80%;
    }

    .aboutSection .aboutSectionWrapper .aboutSectionImg, .aboutSection .aboutSectionWrapper .aboutSectionTxt {
        width: fit-content;
    }

    .aboutSection .aboutSectionWrapper .aboutSectionTxt > h3 {
        font-size: 2.0em;
    }
}

/* Styling for 1024px screens */
@media screen and (max-width: 1024px) {
    .aboutSection {
        width: 100%;
        height: 120dvh;
        margin-top: 64px;
        padding: 20px;
    }

    .aboutSection .aboutSectionWrapper {
        width: 100%;
        height: 95%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .aboutSection .aboutSectionWrapper .aboutSectionImg > img {
        max-width: 100%;
        height: auto;
        border-radius: 4px;
        object-fit: cover;
        box-shadow: 0px 0px 16px #a7a7a71e;
        padding: 0;
        margin: 0;
    }

    .aboutSection .aboutSectionWrapper .aboutSectionImg, .aboutSection .aboutSectionWrapper .aboutSectionTxt {
        width: 50%;
        height: 95%;
    }

    .aboutSection .aboutSectionWrapper .aboutSectionImg {
        height: 70%;
    }
}

/* Tablet Styling for 768px Screen */
@media screen and (max-width: 768px) {
    .aboutSection {
        width: 100%;
        height: fit-content;
        margin-top: 2px;
        padding: 10px;
    }

    .aboutSection .intro {
        font-size: 1.2rem;
    }

    .aboutSection .aboutSectionWrapper {
        width: 100%;
        flex-direction: column;
    }

    .aboutSection .aboutSectionWrapper .aboutSectionImg, .aboutSection .aboutSectionWrapper .aboutSectionTxt {
        width: 80%;
        height: 100%;
    }

    .aboutSection .aboutSectionWrapper .aboutSectionTxt > h3 {
        font-size: 1.8em;
    }

    .aboutSection .aboutSectionWrapper .aboutSectionImg > img {
        max-width: 100%;
        height: auto;
        border-radius: 4px;
        object-fit: cover;
        box-shadow: 0px 0px 16px #a7a7a71e;
        padding: 0;
        margin: 0;
    }

    
}

/* Tablet Styling for 600px screen */
@media screen and (max-width: 600px) {
    .aboutSection {
        width: 100%;
        height: fit-content;
        margin-top: 2px;
        padding: 5px;
    }

    .aboutSection .intro {
        font-size: 1.1rem;
    }

    .aboutSection .aboutSectionWrapper {
        width: 100%;
        flex-direction: column;
    }

    .aboutSection .aboutSectionWrapper .aboutSectionImg, .aboutSection .aboutSectionWrapper .aboutSectionTxt {
        width: 90%;
        height: 100%;
    }

    .aboutSection .aboutSectionWrapper .aboutSectionTxt > h3 {
        font-size: 1.6em;
    }

    .aboutSection .aboutSectionWrapper .aboutSectionImg > img {
        max-width: 100%;
        height: auto;}

    .outblockquote {
        font-size: 1.4em;
        width: 90%;
        margin: 25px auto;
        font-family: Open Sans;
        font-style: italic;
        color: #555555;
        padding: 1.2em 30px 1.2em 75px;
        border-left: 8px solid #0303AB;
        line-height: 1.6;
        position: relative;
        background: #EDEDED;
    }
    
    .outblockquote::before {
        font-family: Arial;
        content: "\201C";
        color: #0303AB;
        font-size: 4em;
        position: absolute;
        left: 10px;
        top: -10px;
    }
    
    .outblockquote::after {
        content: '';
    }
    
    .outblockquote span {
        display: block;
        color: #FF6620;
        font-style: normal;
        font-weight: bold;
        margin-top: 1em;
    }
}

/* Mobile Styling on 480px */
@media screen and (max-width: 480px) {
    .aboutSection {
        width: 100%;
        height: fit-content;
        margin-top: 16px;
        padding: 10px;
    }

    .aboutSection .intro {
        font-size: 1rem;
    }

    .aboutSection .aboutSectionWrapper {
        width: 100%;
        flex-direction: column;
    }

    .aboutSection .aboutSectionWrapper .aboutSectionImg, .aboutSection .aboutSectionWrapper .aboutSectionTxt {
        width: 100%;
        height: 100%;
    }

    .aboutSection .aboutSectionWrapper .aboutSectionImg > img {
        max-width: 100%;
        border-radius: 1px;
        object-fit: cover;
        box-shadow: 0px 0px 16px #a7a7a71e;
        padding: 0;
        margin-left: -1px;
    }

    .aboutSection .aboutSectionWrapper .aboutSectionTxt {
        padding-left: 0px;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: 200;
        color: #000000;
    }

    .aboutSection .aboutSectionWrapper .aboutSectionTxt > h3 {
        font-size: 1.4em;
        font-weight: bold;
        letter-spacing: 1.3px;
        line-height: 1.2;
        margin: 0;
        color: #0303AB;
    }

    .blockquote {
        padding: 6px 4px 2px;
        position: relative;
    }

    .blockquote p {
        font-family: "Utopia-italic";
        font-size: 16px;
        font-weight: 700;
        text-align: left;
    }

    .blockquote:before {
        position: absolute;
        font-family: 'FontAwesome';
        top: 0;
        content: "\f10d";
        font-size: 50px;
        color: rgba(0,0,0,0.1);
    }

    .blockquote::after {
        content: "";
        top: 10px;
        left: 25%;
        margin-left: -100px;
        position: absolute;
        border-bottom: 3px solid #FF6620;
        height: 3px;
        width: 250px;
    }

    .outblockquote {
        font-size: 1.4em;
        width: 100%;
        margin: 50px 0px;
        font-family: Open Sans;
        font-style: italic;
        color: #555555;
        padding: 1.2em 3px 0.2em 75px;
        border-left: 8px solid #0303AB;
        line-height: 1.3;
        position: relative;
        background: #EDEDED;
    }

    .outblockquote::before {
        font-family: Arial;
        content: "\201C";
        color: #0303AB;
        font-size: 6em;
        position: absolute;
        left: 10px;
        top: -10px;
    }

    .outblockquote::after {
        content: '';
    }

    .outblockquote span {
        display: block;
        color: #FF6620;
        font-style: normal;
        font-weight: bold;
        margin-top: 0.5em;
    }
}

/* Mobile Styling on 320px */
@media screen and (max-width: 320px) {
    .aboutSection {
        width: 100%;
        height: fit-content;
        margin-top: 32px;
        padding: 10px;
    }

    .aboutSection .intro {
        font-size: 1rem;
    }

    .aboutSection .aboutSectionWrapper {
        width: 100%;
        flex-direction: column;
    }

    .aboutSection .aboutSectionWrapper .aboutSectionImg, .aboutSection .aboutSectionWrapper .aboutSectionTxt {
        width: 95%;
        height: 100%;
    }

    .aboutSection .aboutSectionWrapper .aboutSectionTxt {
        padding-left: 0px;
        margin-top: 16px;
    }

    .aboutSection .aboutSectionWrapper .aboutSectionTxt > h3 {
        font-size: 1rem;
        letter-spacing: 1.1px;
        line-height: 1.1;
    }

    .blockquote {
        padding: 6px 8px 4px;
        position: relative;
    }

    .blockquote p {
        font-family: "Utopia-italic";
        font-size: 35px;
        font-weight: 700;
        text-align: center;
    }

    .blockquote:before {
        position: absolute;
        font-family: 'FontAwesome';
        top: 0;
        content: "\f10d";
        font-size: 200px;
        color: rgba(0,0,0,0.1);
    }

    .blockquote::after {
        content: "";
        top: 20px;
        left: 50%;
        margin-left: -100px;
        position: absolute;
        border-bottom: 3px solid #bf0024;
        height: 3px;
        width: 200px;
    }

    .outblockquote {
        font-size: 1.4em;
        width: 60%;
        margin: 50px auto;
        font-family: Open Sans;
        font-style: italic;
        color: #555555;
        padding: 1.2em 30px 1.2em 75px;
        border-left: 8px solid #78C0A8;
        line-height: 1.6;
        position: relative;
        background: #EDEDED;
    }

    .outblockquote::before {
        font-family: Arial;
        content: "\201C";
        color: #78C0A8;
        font-size: 4em;
        position: absolute;
        left: 10px;
        top: -10px;
    }

    .outblockquote::after {
        content: '';
    }

    .outblockquote span {
        display: block;
        color: #333333;
        font-style: normal;
        font-weight: bold;
        margin-top: 1em;
    }
}
