.quoteSection {
    background-color: #0303AB;
}

/* Center the blockquote in the page */
.blockquotewrapper {
    display: flex;
    height: 100vh;
    padding: 0 20px;
}

.span {
    color: #ffffff;
}

.quoteHeading {
    width: 80%;
    color: #ffffff;
    padding-left: 10%;
    padding-top: 2%;
    font-size: 2rem;
    font-weight: bold;
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 10px;
}

/* Blockquote main style */
.blockquote {
    position: relative;
    max-width: 620px;
    margin: 80px auto;
    align-self: center;
}

/* Blockquote header */
.blockquote h1 {
    position: relative; /* for pseudos */
    color: #e74848;
    font-size: 2.8rem;
    font-weight: normal;
    line-height: 1;
    margin: 0;
    border: 2px solid #fff;
    border-radius: 20px;
    padding: 25px;
}

/* Blockquote right double quotes */
.blockquote h1:after {
    content: "";
    position: absolute;
    border: 2px solid #e74848;
    border-radius: 0 50px 0 0;
    width: 60px;
    height: 60px;
    bottom: -62px;
    left: 50px;
    border-bottom: none;
    border-left: none;
    z-index: 3;
}

.blockquote h1:before {
    content: "";
    position: absolute;
    width: 80px;
    border: 6px solid #fff;
    bottom: -3px;
    left: 50px;
    z-index: 2;
    margin: -2px 10px;
}

/* Increase header size after 600px */
@media all and (min-width: 600px) {
    .blockquote h1 {
        font-size: 3rem;
        line-height: 1.2;
    }

    .blockquote h4 {
        position: relative;
        color: #ffffff;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.2;
        margin: 0;
        padding-top: 15px;
        z-index: 1;
        margin-left: 150px;
        padding-left: 12px;
    }

    .blockquote h4:first-letter {
        margin-left: -12px;
    }
}

/* Responsive CSS for screens 1024px and below */
@media screen and (max-width: 1024px) {
    .quoteHeading {
        width: 90%;
        padding-left: 5%;
        font-size: 1.8rem;
        margin-bottom: 8px;
    }

    .blockquote h1 {
        font-size: 2.4rem;
        padding: 25px;
    }

    .blockquote h1:after {
        width: 50px;
        height: 50px;
        bottom: -52px;
        left: 40px;
    }

    .blockquote h1:before {
        width: 70px;
        bottom: 0;
        left: 40px;
    }

    .blockquote h4 {
        font-size: 1.1rem;
        padding-top: 12px;
        margin-left: 100px;
        padding-left: 10px;
    }

    .blockquote h4:first-letter {
        margin-left: -10px;
    }
}

/* Responsive CSS for screens 768px and below */
@media screen and (max-width: 768px) {
    .quoteHeading {
        width: 90%;
        padding-left: 5%;
        font-size: 1.6rem;
        margin-bottom: 7px;
    }

    .blockquote h1 {
        font-size: 2.2rem;
        padding: 22px;
    }

    .blockquote h1:after {
        width: 45px;
        height: 45px;
        bottom: -47px;
        left: 35px;
    }

    .blockquote h1:before {
        width: 65px;
        bottom: 0;
        left: 35px;
    }

    .blockquote h4 {
        font-size: 1rem;
        padding-top: 10px;
        margin-left: 80px;
        padding-left: 9px;
        color:#ffffff;
    }

    .blockquote h4:first-letter {
        margin-left: -9px;
    }
}

/* Responsive CSS for screens 600px and below */
@media screen and (max-width: 600px) {
    .quoteHeading {
        width: 100%;
        padding-left: 5%;
        font-size: 1.4rem;
        margin-bottom: 6px;
    }

    .blockquote h1 {
        font-size: 2rem;
        padding: 20px;
    }

    .blockquote h1:after {
        width: 40px;
        height: 40px;
        bottom: -42px;
        left: 30px;
    }

    .blockquote h1:before {
        width: 60px;
        bottom: 0;
        left: 30px;
    }

    .blockquote h4 {
        font-size: 0.9rem;
        padding-top: 9px;
        margin-left: 70px;
        padding-left: 8px;
    }

    .blockquote h4:first-letter {
        margin-left: -8px;
    }
}

/* Responsive CSS for mobile screens below 480px */
@media screen and (max-width: 480px) {
    .blockquotewrapper {
        display: flex;
        height: 70vh;
        padding: 0 20px;
    }

    .quoteHeading {
        width: 100%;
        padding-left: 5%;
        font-size: 1.5rem;
        margin-bottom: 5px;
    }

    .blockquote {
        margin: 40px auto;
    }

    .blockquote h1 {
        font-size: 2rem;
        padding: 20px;
    }

    .blockquote h1:after {
        width: 40px;
        height: 40px;
        bottom: -42px;
        left: 30px;
    }

    .blockquote h1:before {
        width: 60px;
        bottom: 0;
        left: 30px;
    }

    .blockquote h4 {
        font-size: 1rem;
        padding-top: 10px;
        margin-left: 50px;
        padding-left: 8px;
        color: #ffffff;
    }

    .blockquote h4:first-letter {
        margin-left: -8px;
    }
}

/* Responsive CSS for screens below 320px */
@media screen and (max-width: 320px) {
    .blockquotewrapper {
        display: flex;
        height: 60vh;
        padding: 0 10px;
    }

    .quoteHeading {
        width: 100%;
        padding-left: 5%;
        font-size: 1.2rem;
        margin-bottom: 4px;
    }

    .blockquote {
        margin: 30px auto;
    }

    .blockquote h1 {
        font-size: 1.8rem;
        padding: 18px;
    }

    .blockquote h1:after {
        width: 35px;
        height: 35px;
        bottom: -37px;
        left: 25px;
    }

    .blockquote h1:before {
        width: 55px;
        bottom: 0;
        left: 25px;
    }

    .blockquote h4 {
        font-size: 0.9rem;
        padding-top: 8px;
        margin-left: 40px;
        padding-left: 7px;
    }

    .blockquote h4:first-letter {
        margin-left: -7px;
    }
}
