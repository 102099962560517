.secondaryBtn {
    border: 2px solid #FF6620;
    border-radius: 8px;
    color: #FF6620;
    font-weight: bold;
    letter-spacing: 1.3px;
    /* padding: 16px 32px; */
    box-sizing: border-box;
    background-color: transparent;
    font-size: 14px;
    margin: 0px 8px;
    text-transform: capitalize;
    display: inline-block;
    outline: 0;
    border: 0;
    cursor: pointer;
    transition: box-shadow 0.15s ease,transform 0.15s ease;
    will-change: box-shadow,transform;
    box-shadow: 0px 2px 4px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #FF6620;
    height: 38px;
    padding: 0 32px;
    font-size: 14px;
    border-radius: 6px;
    transition: box-shadow 0.15s ease,transform 0.15s ease;
    text-transform: capitalize;
    border: 2px solid #FF6620;
}



.secondaryBtn:hover {
    box-shadow: 0px 4px 8px rgb(45 35 66 / 40%), 0px 7px 13px -3px rgb(45 35 66 / 30%), inset 0px -3px 0px #d6d6e7;
    transform: translateY(-2px);
    background-color: #FF6620;
    color: #fff;
}
.secondaryBtn:active{
    box-shadow: inset 0px 3px 7px #d6d6e7;
    transform: translateY(2px);
}
