.navbar {
    position: fixed;
    width: 100%;
    height: 90px;
    padding: 10px 80px;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0px 0px 16px #6b6b6b60;
    z-index: 1000;
}

.scrollLink {
    cursor: pointer;
  }

.scrollLink:hover {
    color: orange; 
  }

.activeLink {
    color: red;
  }

.navbar .navbarWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar .navbarWrapper .logo {
    width: fit-content;
    height: fit-content;
}

.navbar .navbarWrapper .logo a {
    text-decoration: none;
}

.navbar .navbarWrapper .logo a > img {
    width: 50px;
    height: 50px;
}

.navbar .navbarWrapper .links {
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar .navbarWrapper .links > ul {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0; /* Add this line to remove default margin */
    padding: 0; /* Add this line to remove default padding */
}

.navbar .navbarWrapper .links > ul li {
    list-style: none;
    padding: 3px;
    box-sizing: border-box;

}

.navbar .navbarWrapper .links > ul li > a {
    text-decoration: none;
    color: #0303AB;
    font-weight: bold;
    letter-spacing: 1.0px;
    text-transform: capitalize;
}

.navbar .navbarWrapper .resIcon {
    border: 1px solid #0303AB;
    border-radius: 4px;
    padding: 5px 8px;
    color: #0303AB;
    display: none;
}

.navbar .show, .navbar .noShow {
    width: 100%;
    height: fit-content;
    position: absolute;
    top: 70px;
    left: 0;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    transition: all .8s ease-in-out;
    z-index: 1;
}

.navbar .noShow {
    left: -100%;
}

.navbar .show > ul, .navbar .noShow > ul {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.navbar .show > ul li, .navbar .noShow > ul li {
    list-style: none;
    padding: 10px 0;
    box-sizing: border-box;
}

.navbar .show > ul li > a, .navbar .noShow > ul li > a {
    text-decoration: none;
    color: #0303AB;
    font-weight: bold;
    letter-spacing: 1.2px;
    text-transform: capitalize;
}

/* Mobile Styling */

@media screen and (max-width: 1024px) {
    .navbar .navbarWrapper .resIcon {
        display: flex;
    }
    .scrollLink {
        cursor: pointer;
      }
    .activeLink {
        color: red; /* Change to the color you want for active links */
      }
    .navbar {
        padding: 10px 20px;
        position: relative;
    }
    
    .navbar .navbarWrapper .links {
        display: none;
    }

    .navbar .navbarWrapper .navbarBtn {
        display: none;
    }

    
}

