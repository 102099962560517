.mission {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap; /* Wrap content items when they overflow */
    flex-direction: column; /* Display items under each other */
    align-items: center;
    justify-content: space-evenly;
    margin-top: 5%;
    margin-bottom: 5%;
    border-bottom: 1px solid #ccc; /* Add bottom border */
}

.intro {
    width: 80%;
    color: #000000;
    font-size: 2rem;
    font-weight: bold;
    text-align: left;
    text-transform: capitalize;
    margin-bottom: 10px;
}

.cardheading {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #0303AB;
    text-align: left;
}

.sectionBody {
    color: #fff;
    height: 100%;
    overflow: auto;
    text-align: left;
    font-size: 16px;
    margin-bottom: 10px;
}

.header {
    text-transform: capitalize;
    color:  #FF6620;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: left;
    position: relative;
}

.header::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0;
    width: auto;
    height: 2px;
    background-color: #FFA486; opacity: 0.1;
    animation: lineAnimation 1s infinite;
}

@keyframes lineAnimation {
    0% {
        transform: scaleX(100);
    }
    50% {
        transform: scaleX(200);
    }
}

.info {
    width: 60%;
    margin: 20px;
    padding: 20px;
    text-align: center;
}

.Who,
.What,
.How {
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    text-align: center;
    margin-bottom: 40px;
    text-align: left;
    text-wrap: wrap;
}

/* Desktop Styling for 1200px screens and up */
@media screen and (min-width: 1200px) {
    .mission {
        margin-top: 5%;
        margin-bottom: 5%;
    }

    .info {
        width: 60%;
    }

    .header {
        font-size: 24px;
    }
}

/* Styling for 1024px screens */
@media screen and (max-width: 1024px) {
    .mission {
        height: 65vh;
        margin-top: 32px;
    }

    .intro {
        width: 90%;
    }

    .info {
        width: 70%;
        margin: 20px;
        padding: 20px;
    }

    .header {
        font-size: 22px;
    }
}

/* Tablet Styling for 768px Screen */
@media screen and (max-width: 768px) {
    .mission {
        height: auto;
        margin-top: 32px;
        align-items: center;
    }

    .intro {
        width: 90%;
        font-size: 1.8rem;
    }

    .info {
        width: 80%;
        margin: 15px;
        padding: 15px;
    }

    .header {
        font-size: 20px;
    }

    .Who,
    .What,
    .How {
        font-size: 16px;
    }
}

/* Tablet Styling for 600px screen */
@media screen and (max-width: 600px) {
    .mission {
        height: auto;
        margin-top: 32px;
        align-items: center;
    }

    .intro {
        width: 90%;
        font-size: 1.6rem;
    }

    .info {
        width: 90%;
        margin: 10px;
        padding: 10px;
    }

    .header {
        font-size: 18px;
    }

    .Who,
    .What,
    .How {
        font-size: 14px;
    }
}

/* Mobile Styling on 480px */
@media screen and (max-width: 480px) {
    .mission {
        width: 100%;
        height: fit-content;
        display: flex;
        margin-top: 64px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .intro {
        width: 90%;
        color: #000000;
        font-size: 1.4rem;
        font-weight: bold;
        text-align: left;
        text-transform: capitalize;
        margin-bottom: 10px;
    }

    .info {
        width: 90%;
        margin: 10px;
        padding: 10px;
        text-align: center;
    }

    .header {
        text-transform: capitalize;
        color:  #FF6620;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
        text-align: left;
        position: relative;
    }

    .header::after {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 0;
        width: auto;
        height: 2px;
        background-color: #FFA486; opacity: 0.1;
        animation: lineAnimation 1s infinite;
    }

    .sectionBody {
        color: #000000;
        height: 100%;
        overflow: auto;
        text-align: left;
        font-size: 16px;
        margin-bottom: 10px;
    }

    .Who,
    .What,
    .How {
        font-size: 14px;
        font-weight: 200;
        color: #000000;
        text-align: center;
        margin-bottom: 40px;
        text-align: left;
        text-wrap: wrap;
    }
}

/* Mobile Styling on 320px */
@media screen and (max-width: 320px) {
    .mission {
        width: 100%;
        height: fit-content;
        display: flex;
        margin-top: 64px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .intro {
        width: 90%;
        color: #000000;
        font-size: 1.2rem;
        font-weight: bold;
        text-align: left;
        text-transform: capitalize;
        margin-bottom: 10px;
    }

    .info {
        width: 90%;
        margin: 10px;
        padding: 10px;
        text-align: center;
    }

    .header {
        text-transform: capitalize;
        color:  #FF6620;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
        text-align: left;
        position: relative;
    }

    .header::after {
        content: "";
        position: absolute;
        bottom: -5px;
        left: 0;
        width: auto;
        height: 2px;
        background-color: #FFA486; opacity: 0.1;
        animation: lineAnimation 1s infinite;
    }

    .sectionBody {
        color: #000000;
        height: 100%;
        overflow: auto;
        text-align: left;
        font-size: 14px;
        margin-bottom: 10px;
    }

    .Who,
    .What,
    .How {
        font-size: 12px;
        font-weight: 200;
        color: #000000;
        text-align: center;
        margin-bottom: 40px;
        text-align: left;
        text-wrap: wrap;
    }
}
