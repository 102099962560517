.homeHero {
    width: 100%;
    height: 100dvh;
    background: url(./../../assets/images/techten_hero_image_color.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.homeHero .homeHeroWrapper {
    padding-top: 60px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.homeHero .homeHeroWrapper .heroTxt {
    padding-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeHero .homeHeroWrapper .heroTxt h2 {
    color: #fff;
    font-size: 2.5rem;
    letter-spacing: 1.3px;
    font-weight: bold;
    width: 50%;
    margin-top: 140px;
    text-align: center;
    line-height: 1.3;
}

.homeHero .homeHeroWrapper .items {
    width: 35%;
    height: fit-content;
    padding: 20px;
    margin-top: 30px;
    position: relative;
    left: 200px;
    bottom: 0;
}

.homeHero .homeHeroWrapper .items .item {
    width: 33.33%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: all .5s ease-in-out;
    border-radius: 4px;
    cursor: pointer;
    background: #0303AB;
    margin-top: 10px;
}

.homeHero .homeHeroWrapper .items .item .itemIcon {
    font-size: 1.5rem;
    color: #FF6620;
    align-items: center;
    justify-content: center;
    margin-top: -10px;
}

.homeHero .homeHeroWrapper .items .item > h3 {
    font-size: 0.9rem;
    font-weight: bold;
    color: #FF6620;
    width: 50%;
    text-align: left;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 20px;
}

.homeHero .homeHeroWrapper .items .item:hover {
    background-color: rgb(255, 255, 255);
    transition: all .5s ease-in-out;
    cursor: pointer;
}

.homeHero .homeHeroWrapper .items .item:hover .itemIcon {
    color: #fff;
}

.homeHero .homeHeroWrapper .items .item:hover > h3 {
    color: #FF6620;
}

.homeHero .homeHeroWrapper .items .itemIntro {
    color: #0303AB;
    background-color: #fff;
    font-size: 1.5rem;
    width: 20%;
    text-align: center;
    margin-left: 10px;
    padding-top: 40px;
    margin-bottom: 10px;
}

/* Desktop Styling for 1200px screens and up */
@media screen and (min-width: 1200px) {
    .homeHero .homeHeroWrapper .heroTxt h2 {
        font-size: 3rem;
        width: 60%;
    }

    .homeHero .homeHeroWrapper .items {
        width: 40%;
    }

    .homeHero .homeHeroWrapper .items .item {
        height: 70px;
    }

    .homeHero .homeHeroWrapper .items .item .itemIcon {
        font-size: 1.75rem;
    }

    .homeHero .homeHeroWrapper .items .item > h3 {
        font-size: 1rem;
    }
}

/* Styling for 1024px screens */
@media screen and (max-width: 1024px) {
    .homeHero .homeHeroWrapper .heroTxt h2 {
        width: 90%;
    }

    .homeHero .homeHeroWrapper .items {
        left: 100px;
    }

    .homeHero .homeHeroWrapper .items .item {
        width: 40%;
    }
}

/* Tablet Styling for 768px Screen */
@media screen and (max-width: 768px) {
    .homeHero {
        height: 100dvh;
    }

    .homeHero .homeHeroWrapper {
        padding-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .homeHero .homeHeroWrapper .heroTxt {
        padding-top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .homeHero .homeHeroWrapper .heroTxt h2 {
        font-size: 1.5rem;
        letter-spacing: 1.3px;
        width: 70%;
        margin-top: 40px;
        text-align: center;
        line-height: 1.3;
    }

    .homeHero .homeHeroWrapper .items {
        width: 60%;
        height: fit-content;
        padding: 20px;
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
    }

    .homeHero .homeHeroWrapper .items .item {
        width: 33.33%;
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        transition: all .5s ease-in-out;
        border-radius: 4px;
        cursor: pointer;
        background: #0303AB;
        margin-top: 10px;
        margin-left: 20px;
    }

    .homeHero .homeHeroWrapper .items .item .itemIcon {
        font-size: 1.5rem;
        color: #FF6620;
        align-items: center;
        justify-content: center;
        margin-top: -10px;
    }

    .homeHero .homeHeroWrapper .items .item > h3 {
        font-size: 0.9rem;
        font-weight: bold;
        color: #FF6620;
        width: 50%;
        text-align: left;
        margin-top: 10px;
        margin-left: 10px;
        margin-bottom: 20px;
    }

    .homeHero .homeHeroWrapper .items .itemIntro {
        color: #0303AB;
        background-color: #fff;
        font-size: 1.5rem;
        width: 20%;
        text-align: center;
        margin-left: 10px;
        padding-top: 40px;
        margin-bottom: 10px;
    }
}

/* Tablet Styling for 600px screen */
@media screen and (max-width: 600px) {
    .homeHero {
        height: 100%;
    }

    .homeHero .homeHeroWrapper {
        height: auto;
        justify-content: space-between;
    }

    .homeHero .homeHeroWrapper .heroTxt {
        height: 200px;
    }

    .homeHero .homeHeroWrapper .heroTxt h2 {
        font-size: 2rem;
        width: 90%;
    }

    .homeHero .homeHeroWrapper .items {
        flex-direction: column;
        left: 0;
        align-items: center;
    }

    .homeHero .homeHeroWrapper .items .item {
        width: 70%;
        height: 100px;
    }

    .homeHero .homeHeroWrapper .items .item .itemIcon {
        font-size: 2.5rem;
    }

    .homeHero .homeHeroWrapper .items .item > h3 {
        font-size: 1rem;
    }
}

/* Mobile Styling on 480px */
@media screen and (max-width: 480px) {
    .homeHero {
        height: 100%;
    }

    .homeHero .homeHeroWrapper {
        height: auto;
        justify-content: space-between;
    }

    .homeHero .homeHeroWrapper .heroTxt {
        height: 200px;
    }

    .homeHero .homeHeroWrapper .heroTxt h2 {
        font-size: 1.4rem;
        width: 90%;
    }

    .homeHero .homeHeroWrapper .items {
        flex-direction: column;
        left: 0;
        align-items: center;
    }

    .homeHero .homeHeroWrapper .items .item {
        width: 70%;
        height: 50px;
    }

    .homeHero .homeHeroWrapper .items .item .itemIcon {
        font-size: 1.5rem;
    }

    .homeHero .homeHeroWrapper .items .item > h3 {
        font-size: 0.7rem;
    }
}


/* Mobile Styling on 320px */
@media screen and (max-width: 320px) {
    .homeHero {
        height: fit-content;
    }

    .homeHero .homeHeroWrapper .heroTxt h2 {
        font-size: 1.0rem;
        letter-spacing: 1.1px;
        font-weight: 500;
        width: 85%;
        line-height: 1.1;
    }

    .homeHero .homeHeroWrapper .items .item {
        height: 60px;
    }

    .homeHero .homeHeroWrapper .items .item .itemIcon {
        font-size: 1.5rem;
    }

    .homeHero .homeHeroWrapper .items .item > h3 {
        font-size: 0.7rem;
        width: 80%;
    }
}
