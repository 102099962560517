.banner1 {
    width: 100%;
    height: 50dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 80px;
    box-sizing: border-box;
    background: linear-gradient(rgba(0,0,0,0.1), rgba(3, 3, 171, 1)), url(./../../assets/images/Girl-learning-coding-at-Techten-Ghana.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 64px;
}

.banner1 .banner1Wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.banner1 .banner1Wrapper .banner1Heading {
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 1.3px;
    line-height: 1.3;
    color: #fff;
    text-align: center;
    width: 60%;
    padding-bottom: 40px;
}

.banner1 .banner1Wrapper .banner1Heading > span {
    color: #FF6620;
}

/* Desktop Styling for 1200px screens and up */
@media screen and (min-width: 1200px) {
    .banner1 {
        height: 50dvh;
        padding: 20px 80px;
    }

    .banner1 .banner1Wrapper .banner1Heading {
        width: 60%;
    }
}

/* Styling for 1024px screens */
@media screen and (max-width: 1024px) {
    .banner1 {
        padding: 20px 40px;
    }

    .banner1 .banner1Wrapper .banner1Heading {
        width: 80%;
        margin-bottom: 16px;
    }
}

/* Tablet Styling for 768px Screen */
@media screen and (max-width: 768px) {
    .banner1 {
        padding: 20px 30px;
    }

    .banner1 .banner1Wrapper .banner1Heading {
        width: 90%;
        margin-bottom: 16px;
    }
}

/* Tablet Styling for 600px screen */
@media screen and (max-width: 600px) {
    .banner1 {
        padding: 20px 20px;
    }

    .banner1 .banner1Wrapper .banner1Heading {
        width: 95%;
        margin-bottom: 16px;
    }
}

/* Mobile Styling on 480px */
@media screen and (max-width: 480px) {
    .banner1 {
        width: 100%;
        height: fit-content;
        padding: 20px;
        margin-top: 16px;
    }

    .banner1 .banner1Wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .banner1 .banner1Wrapper .banner1Heading {
        font-size: 1rem;
        line-height: 1.5;
        font-weight: 300;
        letter-spacing: 1.1px;
        text-align: left;
        width: 98%;
        margin-top: 32px;
    }

    .banner1 .banner1Wrapper .banner1Heading > span {
        color: #FF6620;
        font-weight: 900;
    }
}

/* Mobile Styling on 320px */
@media screen and (max-width: 320px) {
    .banner1 {
        width: 100%;
        height: 50dvh;
        padding: 20px;
        margin-top: 32px;
    }

    .banner1 .banner1Wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .banner1 .banner1Wrapper .banner1Heading {
        font-size: 1.2rem;
        font-weight: bold;
        letter-spacing: 1.1px;
        line-height: 1.1;
        color: #fff;
        text-align: center;
        width: 98%;
        margin: 0;
        margin-bottom: 16px;
    }

    .banner1 .banner1Wrapper .banner1Btn {
        display: flex;
    }
}
