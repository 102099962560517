.banner2 {
    width: 100%;
    height: 50dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    box-sizing: border-box;
    background: linear-gradient(rgba(0,0,0,0.6), rgba(3, 3, 171, 1)), url(./../../assets/images/Joel_at_tehten.jpeg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 6px;
}

.banner2 .banner2Wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.banner2 .banner2Wrapper .banner2Heading {
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 1.3px;
    line-height: 1.3;
    color: #fff;
    text-align: center;
    width: 60%;
    margin: 0;
}

.banner2 .banner2Wrapper .banner2Heading > span {
    color: #FF6620;
}

.banner2 .banner2Wrapper .banner2Txt {
    color: #fff;
    width: 60%;
    text-align: left;
    margin: 16px 0;
}

/* Desktop Styling for 1200px screens and up */
@media screen and (min-width: 1200px) {
    .banner2 .banner2Wrapper .banner2Heading {
        width: 60%;
        font-size: 1.3rem;
        text-align: left;
        color: #FF6620;
    }
    .banner2Txt {
        color: #fff;
        width: 60%;
        text-align: left;
        margin: 16px 0;
    }
}

/* Styling for 1024px screens */
@media screen and (max-width: 1024px) {
    .banner2 .banner2Wrapper .banner2Heading {
        width: 80%;
        margin-bottom: 16px;
    }
}

/* Tablet Styling for 768px Screen */
@media screen and (max-width: 768px) {
    .banner2 .banner2Wrapper .banner2Heading {
        width: 90%;
        font-size: 1.0rem;
        margin-bottom: 16px;
    }

    .banner2 .banner2Wrapper .banner2Txt {
        width: 90%;
    }
}

/* Tablet Styling for 600px screen */
@media screen and (max-width: 600px) {
    .banner2 .banner2Wrapper .banner2Heading {
        width: 95%;
        font-size: 1.2rem;
        margin-bottom: 16px;
    }

    .banner2 .banner2Wrapper .banner2Txt {
        width: 95%;
    }
}

/* Mobile Styling on 480px */
@media screen and (max-width: 480px) {
    .banner2 {
        width: 100%;
        height: fit-content;
        padding: 10px;
        margin-top: 32px;
    }

    .banner2 .banner2Wrapper .banner2Heading {
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1.1;
        width: 98%;
        margin: 16px;
        color: #FF6620;

    }

    .banner2 .banner2Wrapper .banner2Heading > span {
        color: #FF6620;
        ;
    }

    .banner2 .banner2Wrapper .banner2Txt {
        width: 98%;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.6;
    }
}

/* Mobile Styling on 320px */
@media screen and (max-width: 320px) {
    .banner2 {
        width: 100%;
        height: fit-content;
        padding: 20px;
        margin-top: 32px;
    }

    .banner2 .banner2Wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .banner2 .banner2Wrapper .banner2Heading {
        font-size: 0.5rem;
        font-weight: bold;
        letter-spacing: 1.1px;
        line-height: 1.1;
        color: #FF6620;
        text-align: center;
        width: 98%;
        margin: 0;
        margin-bottom: 16px;
    }

    .banner2 .banner2Wrapper .banner2Txt {
        width: 98%;
    }

    .banner2 .banner2Wrapper .banner2Btn {
        display: flex;
    }
}
