.servicesSection {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -160px;
    padding: 20px 40px;
}

.heading_container {
    display: flex;
    justify-content: center;
    text-align: center;
}

.intro {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.5rem;
    color: #0303AB;
    width: fit-content;
    margin: 0;
    margin-bottom: 8px;
}

.intro > span {
    font-size: 2rem;
    font-weight: bold;
    text-align: left;
    color: #0303AB;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 100px;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    background-color: rgba(3, 3, 171, 1); /* Add overlay color here */
    border-radius: 40px;
    padding: 20px;
}

.serviceTitle {
    font-weight: bold;
    font-size: 1.2rem;
    color: #FF6620;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.heading_container .h2 {
    font-weight: 700;
    position: relative;
    padding-bottom: 5px;
    text-transform: uppercase;
    margin-top: 32px;
}

.us_section {
    background-image: url(./../../assets/images/scratchcoding-at-techtenplanet.jpeg);
    background-size: cover;
    background-attachment: fixed;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.5); /* Add overlay color here */
    border-radius: 40px;
    padding: 20px;
}

.us_section .us_container {
    padding-top: 25px;
}

.us_section .us_container .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 25px 10px 0 10px;
}

.us_section .us_container .box .img-box {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.us_section .us_container .box .img-box img {
    max-width: 100%;
}

.us_section .us_container .box .detail-box h5 {
    font-weight: bold;
}

.servicesCards {
    width: 100%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.serviceBrief {
    font-size: 1.5rem;
    margin-top: 32px;
}

.serviceName {
    font-size: 2rem;
    font-weight: 900;
    margin: 0;
    color: #FF6620;
    margin-bottom: 50px;
}

.serviceCard {
    width: 23.3%;
    height: 80%;
}

.serviceName {
    font-size: 1.5rem;
    margin: 0;
    color: #FF6620;
}

.serviceLink {
    color: #0303AB;
    text-decoration: none;
}

.serviceLink:hover {
    text-decoration: underline;
}

/* Desktop Styling for 1200px screens and up */
@media screen and (min-width: 1200px) {
    .servicesSection {
        height: 100vh;
        padding: 20px 40px;
    }

    .intro {
        font-size: 1.5rem;
    }

    .servicesCards {
        height: 90%;
        justify-content: space-evenly;
    }

    .serviceCard {
        width: 23.3%;
    }
}

/* Styling for 1024px screens */
@media screen and (max-width: 1024px) {
    .servicesSection {
        height: fit-content;
        margin-top: 32px;
        padding: 20px;
    }

    .intro {
        font-size: 1.2rem;
    }

    .servicesCards {
        height: fit-content;
        flex-wrap: wrap;
    }

    .serviceCard {
        width: 45%;
        margin-bottom: 16px;
    }
}

/* Tablet Styling for 768px Screen */
@media screen and (max-width: 768px) {
    .servicesSection {
        height: fit-content;
        margin-top: 32px;
        padding: 20px;
    }

    .intro {
        font-size: 1rem;
    }

    .servicesCards {
        height: fit-content;
        flex-wrap: wrap;
    }

    .serviceCard {
        width: 45%;
        margin-bottom: 16px;
    }
}

/* Tablet Styling for 600px screen */
@media screen and (max-width: 600px) {
    .servicesSection {
        height: fit-content;
        margin-top: 32px;
        padding: 20px;
    }

    .intro {
        font-size: 1rem;
    }

    .servicesCards {
        height: fit-content;
        flex-wrap: wrap;
    }

    .serviceCard {
        width: 45%;
        margin-bottom: 16px;
    }
}

/* Mobile Styling on 480px */
@media screen and (max-width: 480px) {
    .servicesSection {
        width: 100%;
        height: fit-content;
        margin-top: 32px;
        padding: 20px;
    }

    .intro {
        font-size: 0.9rem;
        margin-bottom: 16px;
    }

    .servicesCards {
        height: fit-content;
        flex-wrap: wrap;
    }

    .serviceCard {
        width: 95%;
        margin-bottom: 16px;
        box-shadow: 0px 0px 16px #3131313b;
        padding: 20px;
        border-radius: 4px;
    }

    .serviceName {
        font-size: 1.5rem;
        margin: 0;
    }

    .serviceLink {
        color: #0303AB;
        text-decoration: none;
    }

    .serviceLink:hover {
        text-decoration: underline;
    }
}

/* Mobile Styling on 320px */
@media screen and (max-width: 320px) {
    .servicesSection {
        width: 100%;
        height: fit-content;
        margin-top: 32px;
        padding: 20px;
    }

    .intro {
        font-size: 0.8rem;
    }

    .servicesCards {
        height: fit-content;
        flex-wrap: wrap;
    }

    .serviceCard {
        width: 95%;
        margin-bottom: 16px;
        box-shadow: 0px 0px 16px #3131313b;
        padding: 20px;
        border-radius: 4px;
    }

    .serviceName {
        font-size: 1.5rem;
        margin: 0;
    }

    .serviceLink {
        color: #0303AB;
        text-decoration: none;
    }

    .serviceLink:hover {
        text-decoration: underline;
    }
}
